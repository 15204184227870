import { ITabContainerProps, TabContainerVariant } from "../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import { useComputed, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { getClassName, getTestId, getsxObject } from "../library/utils";
import { Box, Stack, Tabs } from "@mui/material";
import { containerWithPaper } from "../generators/GeneratorUtils";
import { LayoutItem, TabItem } from "../../resolvers-types";
import NGTabItem from "./NGTabItem";
import NGReviewDialog from "../components/NGReviewDialog/NGReviewDialog";
import NGLayoutItem from "../generators/NGLayoutItem";
import useResizable from "../hooks/useResizable";
import { useState } from "react";

const tag = "NGTabContainer";

export default function NGTabContainer({ config, context }: ITabContainerProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      TabClasses: useSignal(config.TabClasses ?? []),
      Resizable: useSignal(config.Resizable ?? false),
      BoxStyle: useSignal(config.BoxStyle ?? {}),
      TabStyle: useSignal(config.TabStyle ?? {}),
      ContentClasses: useSignal(config.ContentClasses ?? []),
      Visible: useSignal(config.Visible ?? true),
      InReviewMode: useSignal(config.InReviewMode || false),
      SelectedTabId: useSignal(
        config.SelectedTabId ?? (config.Items && config.Items.length > 0) ? config.Items[0]?.Id : null
      ),
      SelectedTabTitle: useSignal(
        config.SelectedTabTitle ?? (config.Items && config.Items.length > 0) ? config.Items[0]?.Title : null
      ),
      MaxTabs: useSignal(config.MaxTabs ?? null),
    },
    context
  );

  const showMore = useSignal(local.MaxTabs.value ? config.Items.length > local.MaxTabs.value : false);

  const selectedTab = useComputed(() => {
    let index = config.Items?.findIndex((item) => item.Id === local.SelectedTabId.value);
    // if SelectedTabTitle is set, use it to find the index
    if (config.Bindings?.SelectedTabTitle) {
      index = config.Items?.findIndex((item) => item.Title === local.SelectedTabTitle.value);
    }

    return index > -1 ? index : 0;
  });

  const handlers = setupHandlers(config, context);

  if (!isNil(config.ReviewDialogOptions)) {
    (config.ReviewDialogOptions as any).ContextId = (config as any).ContextId;
  }

  const { resizable } = useResizable(local.Resizable.value);

  function renderContainer() {
    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
      const { Title, Id } = config.Items[newValue] as TabItem;
      local.SelectedTabId.value = Id;
      local.SelectedTabTitle.value = Title;
      if (handlers["onChange"]) {
        handlers["onChange"](e, { Index: newValue, Id, Title });
      }
    };

    return (
      <Box
        sx={getsxObject(local.BoxStyle.value)}
        data-testid={getTestId(config)}
        data-type={config.__typename}
        className={getClassName(local.Classes)}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tabs
            value={selectedTab.value}
            aria-label={config.AriaLabel ?? "tabs"}
            className={getClassName(local.TabClasses)}
            sx={getsxObject(local.Style.value)}
            TabIndicatorProps={{ sx: config.TabIndicatorStyles }}
            variant={(config.Variant as TabContainerVariant) ?? "standard"}
            {...handlers}
            onChange={handleChange}
          >
            {config.Items?.filter(
              (x, i) =>
                x.__typename == "TabItem" && (local.MaxTabs.value == null || !showMore.value || i < local.MaxTabs.value)
              // (x, i) => x.__typename == "TabItem"
            ).map((tab, key) => {
              const configOnClick = tab.Actions?.find((x: any) => x.Trigger === "onClick");
              const preHandler = (handlerName: string, action: any, e: any, data: any, formCtx: any) => {
                handleChange(e, data);
              };

              const onClick = configOnClick
                ? { ...configOnClick, preHandler: preHandler }
                : {
                    Trigger: "onClick",
                    preHandler: (handlerName: string, action: any, e: any, data: any, formCtx: any) => {
                      handleChange(e, data);
                    },
                  };

              return (
                <NGTabItem
                  key={key}
                  selected={selectedTab.value === key}
                  config={
                    {
                      ...tab,
                      TabIndex: key,
                      Style: { ...local.TabStyle.value, ...tab.Style },
                      Actions: [...((tab.Actions as any) || []), onClick],
                    } as TabItem
                  }
                  context={context}
                />
              );
            })}
            {showMore.value && (
              <NGTabItem
                key={config.Id + "more"}
                config={
                  {
                    __typename: "TabItem",
                    Id: config.Id + "more",
                    Title: "More",
                    TabIndex: local.MaxTabs.value,
                    Style: { ...local.TabStyle.value },
                    Actions: [
                      {
                        Trigger: "onClick",
                        preHandler: () => (showMore.value = !showMore.value),
                      },
                    ],
                  } as TabItem
                }
                context={context}
              />
            )}
          </Tabs>
          {config.Items?.filter((x) => x.__typename != "TabItem").map((item, key) => (
            <NGLayoutItem key={key} config={item as LayoutItem} context={context} />
          ))}
        </Stack>
        <div className={getClassName(local.ContentClasses)}>
          {config.Items?.map((tab, tabkey) => {
            return tab.Items?.map((item, key) => {
              if (isNil(item)) {
                return <></>;
              }
              // The Box below is necessary to avoid error: "Each child in a list should have a unique "key" prop"
              return (
                <Box key={`${tabkey}-${key}`}>
                  {selectedTab.value === tabkey && <NGLayoutItem config={item} context={context} />}
                </Box>
              );
            });
          })}
        </div>
        {local.InReviewMode.value && (
          <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={context}></NGReviewDialog>
        )}
      </Box>
    );
  }

  return resizable(containerWithPaper(local, config, renderContainer, context));
}
