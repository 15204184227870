import { useSignalEffect } from "@preact/signals-react";
import { log } from "./logger";
import { client } from "./nats-client";

const tag = "finapp";
let loaded = false;

export function useFinApp(onSearch) {
  useSignalEffect(() => {
    if (loaded) return;

    (async () => {
      try {
        const m = await import("https://fwc-ui.markitqa.com/fwc-load-app-fwc-all-apps.js");
        let token;
        let renewingTokenPromise: Promise<any> | null;

        const theme = getTheme();

        const getToken = async () => {
          if (!renewingTokenPromise) {
            renewingTokenPromise = (async () => {
              try {
                token = await requestToken();
              } finally {
                renewingTokenPromise = null;
              }
              return token;
            })();
          }
          return renewingTokenPromise;
        };

        const tokenProvider = (renewToken) => (renewToken || token === undefined ? getToken() : token);
        const renewToken = getToken;

        const finctx = await m.registerFinApps({
          tokenProvider,
          renewToken,
          attributes: {
            theme,
          },
        });
        log.info(tag, "Fin App Context", finctx);

        finctx.addEventListener("search-selection", (e) => {
          const data = e?.detail?.data;
          if (!data) return;
          if (onSearch) onSearch(data);
        });
        loaded = true;
      } catch (ex) {
        log.error(tag, "Failed to load fin apps", ex);
      }
    })();
  });
}

function getTheme() {
  return document.body.classList.contains("dark-theme") ? "dark" : "light";
}

async function requestToken() {
  const finAppTokenCreate = await client.request<{ Token: string }>("finapptoken.create", {});

  if (!finAppTokenCreate.success || finAppTokenCreate.data === undefined) {
    log.error(tag, "finapp create token failed", { finAppTokenCreate });

    // Temporary token, Fincentric auth is only setup for Communify Test
    return "DCvt8Kk1xeYn834f3qDVRtHGmmv0"; // Expires 2/12/2025 10:52:12 PM (ET)
  }

  return finAppTokenCreate.data.Token;
}
