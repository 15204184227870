import { __Type } from "graphql";
import * as graphql from "../../../resolvers-types";
import {
  editFormats,
  editSampleData,
  createNewPropertyDialog,
  editStyles,
  popupServices,
  complexSampleDataEditorPopup,
  editActionsPopup,
  editInputsPopup,
  editCellLayout,
} from "../../generators/PropertiesEditorHelpers";
import { componentLibrary, controlLibrary } from "./controlLibrary";

export const guiActions: graphql.IActionTrigger[] = [
  {
    Trigger: "onGridConfigurationChange",
    CommandSet: {
      FirstCommandId: "1",
      ExecuteCommandsInParallel: false,
      Commands: [
        {
          Id: "1",
          Instruction: {
            Name: "SetState",
          },
          Parameters: [
            {
              Name: "Bindings",
              Value: {
                "State.SelectedComponent": "Event.Config",
              },
            },
          ],
          NextCommandIdOnSuccess: "2",
        },
        {
          Id: "2",
          Instruction: {
            Name: "SendMessageToParent",
          },
          Parameters: [
            {
              Name: "Bindings",
              Value: {
                SelectedComponent: "Event.Config",
                Type: "'onComponentUpdate'",
              },
            },
          ],
        },
      ],
    },
  },
];

export const setPageCommand = {
  Id: "2",
  Instruction: {
    Name: "SendMessageToChild",
  },
  Parameters: [
    {
      Name: "IFrame",
      Value: "EditorIFrame",
    },
    {
      Name: "Type",
      Value: "setPage",
    },
    {
      Name: "Bindings",
      Value: {
        Page: "State.Page",
      },
    },
    {
      Name: "Actions",
      Value: [
        {
          Trigger: "setPage",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.Page": "merge(State.Page,Event.Page)",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "SendMessageToParent",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      Page: "State.Page",
                      Type: "'onPageUpdate'",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

const setEditor = (source: string) => {
  const modSource = source[0].toUpperCase() + source.slice(1);

  const propertiesEditor = {
    __typename: "SimpleContainer",
    Id: "PropertiesEditor",
    Classes: ["properties-editor"],
    UniqueName: "PropertiesEditor",
    Resizable: { startWidth: "25%", direction: "left" },
    Items: [
      {
        Typename: "PropertiesEditor",
        Id: "PropertiesEditor/A/1",
        UseFieldGroups: true,
        AddAncillaryButtons: true,
        FieldsReadOnly: ["Id", "ReferenceId", "ReferenceType"],
        UniqueName: "PropertiesEditor/A/1",
        Bindings: {
          Data: "State.PropertiesEditorData",
          ViewMode: "State.EditorViewMode",
          SearchPropInput: "State.PropertiesEditorState.SearchPropInput",
          ToggleExpanded: "State.ToggleExpanded",
        },
        HeaderItems: [
          {
            __typename: "Button",
            Classes: ["button-tertiary-small"],
            Id: "Settings",
            StartIcon: {
              IconName: "SettingsOutlined",
            },
            Bindings: {
              Visible: `State.PropertiesEditorData.Id==State.SelectedItem.ComponentContainer.Id`,
            },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.PropertiesEditorData": `State.SelectedItem`,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
        Actions: [
          {
            Trigger: "onSave",
            CommandSet: {
              Id: "cs_pf_applyButton",
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Id: "i_pf_applyButton",
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "findMeta(State.Page,Form.Id)": "merge(State.PropertiesEditorData, getFormData(Form))",
                        "State.PropertiesEditorData": "Form",
                      },
                    },
                    {
                      Name: "Merge",
                      Value: true,
                    },
                    {
                      Name: "Form",
                      Value: "NGPropEditor",
                    },
                  ],
                  NextCommandIdOnSuccess: "2",
                },
                setPageCommand,
              ],
            },
          },
          {
            Trigger: "onDelete",
            CommandSet: {
              Id: "cs_pf_deleteButton",
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Id: "0x346d",
                    Name: "ShowMessage",
                  },
                  Parameters: [
                    { Name: "Title", Value: "Delete" },
                    {
                      Name: "Message",
                      Value: "Are you sure you want to delete this item?",
                    },
                    { Name: "ShowOkButton", Value: true },
                    { Name: "ShowCancelButton", Value: true },
                  ],
                  NextCommandIdOnSuccess: "3",
                },
                {
                  Id: "3",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "deleteMeta(State.Page,Form.Id)": "null",
                        "State.PropertiesEditorData": "null",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "2",
                },
                setPageCommand,
              ],
            },
          },
        ],
      } as graphql.PropertiesEditor,
    ],
  } as graphql.SimpleContainer;

  const IFrameSavingIndicator = {
    Id: "IFrameSavingIndicatorContainer",
    __typename: "SimpleContainer",
    Style: {
      width: "70px",
    },
    Items: [
      {
        Id: "IFrameSavingIndicator-SavingMessage",
        __typename: "Label",
        Bindings: {
          Value: `State.NGService.Save${modSource}.Loading ? 'Saving...' : State.NGService.Save${modSource}.State=='error'?'Not Saved':State.NGService.Save${modSource}.State=='success'?'Saved':''`,
          Style: `{color: State.NGService.Save${modSource}.State=='error'?'var(--text-error, red)':'var(--md-sys-color-on-surface, #2E2E2E)', fontFamily: 'Inter',fontSize:'12px' }`,
        },
      },
    ],
  };

  const IFrameDesignerViewSelectors = [
    {
      Id: "contextMenuForViewSelectors",
      __typename: "ContextMenu",
      Content: {
        __typename: "SimpleContainer",
        Layout: {
          __typename: "FlexLayout",
          Classes: ["component-editor-context-menu"],
        },
        Items: [
          {
            __typename: "Switch",
            Id: "dwefweq",
            Label: "Show all hidden components",
            Bindings: {
              Value: "State.EditorShowInvisibleComponents",
            },
            Actions: [
              {
                Trigger: "onChange",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "toggleInvisibleComponents",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            Value: "Event",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "Switch",
            Id: "dwefwsw2eq",
            Label: "Ignore Sample Data in Editor",
            Bindings: {
              Value: "State.IgnoreSampleDataInEditor",
            },
            Actions: [
              {
                Trigger: "onChange",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "toggleIgnoreSampleDataInEditor",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            Value: "Event",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "Switch",
            Id: "ewefwsw2ep",
            Label: "Interactive Mode",
            Bindings: {
              Value: "State.InteractionMode",
            },
            Actions: [
              {
                Trigger: "onChange",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "setInteractionMode",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            Value: "Event",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      Id: "xndepivycl",
      __typename: "SimpleContainer",
      Layout: {
        __typename: "FlexLayout",
        Direction: "row",
      },
      Style: {
        paddingRight: "5px",
      },
      Items: [
        {
          __typename: "Button",
          Id: "btnPreview",
          TopIcon: { IconName: "Preview" },
          Classes: ["button-tertiary-small"],
          Title: "Preview",
          Actions: [
            {
              Trigger: "onClick",
              CommandSet: {
                FirstCommandId: "1",
                ExecuteCommandsInParallel: false,
                Commands: [
                  {
                    Id: "1",
                    Instruction: {
                      Id: "1",
                      Name: "GoToPage",
                    },
                    Parameters: [
                      {
                        Name: "Page.Url",
                        Value: "/local/component",
                      },
                      {
                        Name: "URLBindings",
                        Value: {
                          Id: "State.ModelId",
                        },
                      },
                      {
                        Name: "OpenInNewWindow",
                        Value: true,
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        {
          Id: "suigefyuob",
          __typename: "Button",
          Classes: ["button-tertiary-small"],
          TopIcon: { IconName: "ContentCopyOutlined" },
          Title: "Copy",
          Actions: [
            {
              Trigger: "onClick",
              CommandSet: {
                FirstCommandId: "1",
                ExecuteCommandsInParallel: false,
                Commands: [
                  {
                    Id: "1",
                    Instruction: {
                      Name: "SetState",
                    },
                    Parameters: [
                      {
                        Name: "Bindings",
                        Value: {
                          "State.CopiedComponent": "State.PropertiesEditorData",
                        },
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        {
          Id: "yujyidecqe",
          __typename: "Button",
          Classes: ["button-tertiary-small"],
          TopIcon: { IconName: "ContentPasteOutlined" },
          Title: "Paste",
          Actions: [
            {
              Trigger: "onClick",
              CommandSet: {
                FirstCommandId: "1",
                ExecuteCommandsInParallel: false,
                Commands: [
                  {
                    Id: "1",
                    Instruction: {
                      Name: "SetState",
                    },
                    Parameters: [
                      {
                        Name: "Bindings",
                        Value: {
                          "State.Page":
                            "generatePageWithClonedMeta(State.Page, State.PropertiesEditorData, State.CopiedComponent)",
                        },
                      },
                    ],
                    NextCommandIdOnSuccess: "2",
                  },
                  setPageCommand,
                ],
              },
            },
          ],
        },
        {
          Id: "ewdqfr345yh",
          __typename: "Button",
          TopIcon: { IconName: "VideoSettingsOutlined" },
          Classes: ["button-tertiary-small"],
          Title: "Settings",
          Actions: [
            {
              Trigger: "onClick",
              CommandSet: {
                FirstCommandId: "1",
                ExecuteCommandsInParallel: false,
                Commands: [
                  {
                    Id: "1",
                    Instruction: {
                      Name: "OpenContextMenu",
                    },
                    Parameters: [
                      {
                        Name: "ContextMenuId",
                        Value: "contextMenuForViewSelectors",
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ];

  const IFrameEditorViewMode = {
    __typename: "MultiSelect",
    Id: "EditorViewMode",
    Name: "EditorViewMode",
    Classes: ["editor-view-mode"],
    Value: "All",
    Bindings: {
      Value: "State.EditorViewMode",
    },
    MultiSelectPossibleValues: [
      { Value: "All", Label: "View All" },
      { Value: "Designer", Label: "Design View" },
      { Value: "Developer", Label: "Dev. View" },
    ],
    LabelExpression: "Label",
    ValueExpression: "Value",
    DisableClearable: true,
    FreeSolo: false,
    Multiple: false,
    Style: {
      minWidth: "180px",
      padding: "0px",
      margin: "0px",
      "&.MuiAutocomplete-input": {
        padding: "0px !important",
      },
    },
  } as graphql.MultiSelect;

  const IFrameToolbarItems = [
    {
      __typename: "ResolutionButtons",
      Id: "resolutionButtons",
      InitialState: source === "page" ? "Home" : undefined,
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            Id: "/1",
            FirstCommandId: "/1",
            Commands: [
              {
                Id: "/1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.IFrameHeight": "Event.SetHeight",
                      "State.IFrameWidth": "Event.SetWidth",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
      Style: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      __typename: "UndoRedoToolbar",
      Id: "undoRedoToolbarButtons",
      Bindings: {
        TrackedObject: "{ SelectedItem: State.SelectedItem }",
        DisableKeyActions: "State.NGDialog.EditActionsPopup.Open",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.Page.Items[0]": "State.SelectedItem",
                      "State.PropertiesEditorData": "findMeta(State.Page, State.PropertiesEditorData.Id)",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              setPageCommand,
            ],
          },
        },
      ],
      Style: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const componentIFrameContainer = {
    Typename: "Form",
    __typename: "Form",
    Id: "0xf0001",
    UniqueName: "PageIFrameForm",
    Classes: ["editor-iframe"],
    Style: {
      backgroundColor: "#f2f2f2",
      flex: 1,
    },
    Items: [
      {
        __typename: "IFrame",
        Id: "EditorIFrame",
        Name: "Page",
        Source: "/ngeditor/component",
        Style: {
          position: "relative",
          border: "solid 1px #e5e5e5",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          marginTop: "16px",
        },
        Bindings: {
          Width: "State.IFrameWidth",
          Height: "'2000vh'",
        },
        Actions: [
          {
            Trigger: "onSelectedComponent",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.PropertiesEditorTypeName": "null",
                        "State.PropertiesEditorData": "null",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "1",
                },
                {
                  Id: "1",
                  Instruction: {
                    Name: "ClearForm",
                  },
                  Parameters: [
                    { Name: "Form", Value: "NGPropEditor" },
                    { Name: "Purge", Value: true },
                  ],
                  NextCommandIdOnSuccess: "2",
                },
                {
                  Id: "2",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.PropertiesEditorTypeName":
                          "Event.SelectedComponent.__typename || Event.SelectedComponent.Typename",
                        "State.PropertiesEditorData": "Event.SelectedComponent",
                        "State.PropertiesEditorState": "clone(Event.SelectedComponent)",
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onComponentUpdate",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.PropertiesEditorTypeName":
                          "Event.SelectedComponent.__typename || Event.SelectedComponent.Typename",
                        "State.PropertiesEditorData": "Event.SelectedComponent",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "1",
                },
                {
                  Id: "1",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "findMeta(State.Page,Event.SelectedComponent.Id)": "Event.SelectedComponent",
                      },
                    },
                    {
                      Name: "Merge",
                      Value: true,
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onPageUpdate",
            CommandSet: {
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.Page": "Event.Page",
                        "State.SelectedItem": "findMeta(Event.Page, State.SelectedItem.Id)",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "2",
                },
                {
                  Id: "2",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.PropertiesEditorData": "findMeta(Event.Page, State.PropertiesEditorData.Id)",
                      },
                    },
                    {
                      Name: "Merge",
                      Value: true,
                    },
                  ],
                  NextCommandIdOnSuccess: "3",
                },
                {
                  Id: "3",
                  Instruction: {
                    Name: "CallService",
                  },
                  Parameters: [{ Name: "ServiceName", Value: `Save${modSource}` }],
                },
              ],
            },
          },
          {
            Trigger: "onGridEditorUpdate",
            CommandSet: {
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.GridEditor.Data": "Event.Data",
                        "State.GridEditor.State": "Event.Data",
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onInit",
            CommandSet: {
              Id: "1",
              FirstCommandId: "1",
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "SendMessageToChild",
                  },
                  Parameters: [
                    {
                      Name: "IFrame",
                      Value: "EditorIFrame",
                    },
                    {
                      Name: "Type",
                      Value: "setPage",
                    },
                    {
                      Name: "Bindings",
                      Value: {
                        Page: "State.Page",
                      },
                    },
                    {
                      Name: "Actions",
                      Value: [
                        {
                          Trigger: "setPage",
                          CommandSet: {
                            FirstCommandId: "1",
                            ExecuteCommandsInParallel: false,
                            Commands: [
                              {
                                Id: "1",
                                Instruction: {
                                  Name: "SetState",
                                },
                                Parameters: [
                                  {
                                    Name: "Bindings",
                                    Value: {
                                      "State.Page": "Event.Page",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    ],
  };

  const componentServices: graphql.Service[] = [
    {
      Name: "QueryComponents",
      Service: "project.component.query",
      Fields: [],
      Bindings: {
        "State.Components": "Result.Entities",
      },
    },
  ];

  const services: graphql.Service[] = [
    ...popupServices,
    ...componentServices,
    {
      Name: "uimetadata.create",
      Service: "uimetadata.create",
      Trigger: "Action",
      Fields: [
        {
          Name: "Item",
          Bindings: {
            Value:
              "{'ComponentId':Form.ReferenceId,'ComponentType':Form.ReferenceType,'ReplaceParentReferences': true}",
          },
        },
      ],
      Bindings: {
        "State.NewDetachedContainer": "Result.OutputObject.ComponentContainer",
      },
    },
    {
      Name: `Get${modSource}`,
      Service: `project.${source}.get`,
      Fields: [
        {
          Name: "Id",
          Required: true,
          Bindings: {
            Value: "State.ModelId",
          },
        },
      ],
      Bindings: {
        "State.SelectedItem": "mergeTypename(Result.Entity)",
        "State.Page.Items[0]": "mergeTypename(Result.Entity)",
      },
      Trigger: null,
    },
    {
      Name: `Save${modSource}`,
      Service: `project.${source}.save`,
      Trigger: "Action",
      Debounce: 5000,
      Fields: [
        {
          Name: "Entity",
          Required: true,
          Bindings: {
            Value: "State.SelectedItem",
          },
        },
      ],
      Bindings: {},
    },
  ];

  const componentEditorGuiStyles = {
    ".library-group-header": {
      "&.MuiButtonBase-root": {
        border: "0.75px solid var(--md-sys-color-outline-variant, rgba(0, 0, 0, 0.12))",
        borderRight: "0",
        background: "var(--md-sys-color-surface-container, #F5F5F7)",
        height: "50px",
        gap: "0.5rem",
        minHeight: "50px !important",
        flexDirection: "row-reverse",
        ".MuiAccordionSummary-expandIconWrapper": {
          color: "var(--md-sys-color-on-surface, #2E2E2E)",
        },
        ".MuiAccordionSummary-content": {
          color: "var(--md-sys-color-on-surface, #2E2E2E)",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
      },
    },

    ".icons-group-header": {
      "&.MuiButtonBase-root": {
        borderRadius: "2px",
        background: "var(--md-sys-color-primary-container, #679cb6)",
        height: "32px",
        minHeight: "32px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
          color: "var(--md-sys-color-on-primary-container, #ffffff)",
        },
        ".MuiAccordionSummary-content": {
          color: "var(--md-sys-color-on-primary-container, #f2f2f2)",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "150%",
        },
      },
    },

    ".prop-editor-checkbox": {
      "&.MuiFormControlLabel-root": {
        width: "100%",
        height: "20px",
        margin: 0,
        justifyContent: "space-between",
      },
      ".MuiTypography-root": {
        color: "var(--md-sys-color-on-surface, #323232)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
    },

    ".prop-editor-right-layouts": {
      ".MuiAutocomplete-root": {
        flex: 1,
      },
      ".MuiFormLabel-root": {
        fontSize: "14px",
        lineHeight: "normal",
        "&.MuiInputLabel-shrink.MuiInputLabel-animated": {
          paddingTop: "2px",
        },
      },
      "fieldset legend span": {
        fontSize: "11px",
      },
      "> .MuiFormControl-root": {
        flex: 1,
        "input.MuiInputBase-input": {
          height: "24px",
          padding: "4px 8px",
        },
      },
    },
    ".radio-input-properties": {
      border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      padding: 0,
      borderRadius: "4px",
    },
    ".properties-editor-accordion.MuiPaper-root": {
      boxShadow: "none",
      "&.Mui-expanded": {
        margin: 0,
      },
      ".MuiAccordionDetails-root": {
        padding: "12px 15px",
        gap: "10px",
        display: "flex",
        flexDirection: "column",
      },
      "> .MuiButtonBase-root": {
        background: "var(--md-sys-color-surface-container, #F5F5F7)",
        height: "50px",
        gap: "0.5rem",
        borderWidth: "0.75px 0px",
        borderStyle: "solid",
        borderColor: "var(--md-sys-color-outline-variant, rgba(0, 0, 0, 0.12))",
        flexDirection: "row-reverse",
        minHeight: "50px !important",
        ".MuiAccordionSummary-expandIconWrapper": {
          color: "var(--md-sys-color-on-surface, #2E2E2E)",
        },
        ".MuiAccordionSummary-content": {
          color: "var(--md-sys-color-on-surface, #2E2E2E)",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        },
      },
    },

    ".designer-view-selectors-button.MuiButtonBase-root": {
      width: "32px",
      minWidth: "32px !important",
      span: {
        margin: 0,
        width: "32px",
      },
      marginRight: "10px",
    },

    ".prop-editor-base-layout": {
      padding: "0 5px",
      borderBottom: "#cecece solid 1px",
    },

    ".prop-editor-action-buttons-header.MuiStack-root": {
      minHeight: "55px",
      margin: 0,
      position: "sticky",
      zIndex: 2,
      top: 0,
      background: "var(--md-sys-color-surface-container, #F5F5F7)",
    },
    ".label-color": {
      color: "var(--text-secondary,#323232)",
    },
    ".prop-editor-action-button.MuiButtonBase-root": {
      color: "#323232",
      width: "32px",
      minWidth: "32px !important",
      flex: 1,
      span: {
        margin: 0,
        width: "32px",
      },
    },

    ".prop-editor-input.MuiFormControl-root": {
      marginTop: 0,
      label: {
        color: "var(--md-sys-color-on-surface-dim, rgba(46, 46, 46, 0.60))",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
      input: {
        fontFamily: "Inter",
        fontSize: "13px",
      },
      ".MuiInputBase-root input": {
        height: "24px",
        padding: "8px 12px",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
        borderColor: "var(--md-sys-color-outline-variant, #86868B)",
      },
    },

    ".color-picker-container": {
      width: "100%",
      border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      borderRadius: "4px",
      "&:hover": {
        border: "1px solid var(--md-sys-color-on-surface, black)",
      },
    },
    ".color-picker-text-field.MuiFormControl-root": {
      marginTop: 0,
      height: "40px",
      input: {
        fontFamily: "Inter",
        fontSize: "13px",
        padding: "8px 12px !important",
      },
      ".MuiInputBase-root input": {
        height: "24px",
        padding: "8px 12px",
      },
      "& .MuiInputBase-root": {
        padding: "0 !important",
        borderRight: "1px solid var(--md-sys-color-outline-variant, #86868B)",
        height: "38px",
        borderRadius: 0,
      },
      label: {
        top: "-3px !important",
        color: "var(--md-sys-color-on-surface-dim, rgba(46, 46, 46, 0.60))",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
      fieldset: {
        outline: "none !important",
        border: "none",
      },
      "&.MuiFormControl-root": {
        height: "38px",
      },
    },
    ".color-picker-alpha-field.MuiFormControl-root": {
      flex: "initial",
      "& .MuiInputBase-root": {
        padding: "0 !important",
        height: "38px",
      },
      label: {
        top: "-3px !important",
      },
      ".MuiOutlinedInput-notchedOutline": {
        outline: "none !important",
        border: "none",
      },
      "input[type=number]": {
        padding: "8px 0 8px 12px !important",
        MozAppearance: "textfield",
      },
      "&.MuiFormControl-root": {
        height: "38px",
        width: "90px",
      },
      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
    ".color-picker-button.MuiButton-root": {
      borderRight: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      padding: "8px 12px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      borderRadius: 0,
    },
    ".menu-button-prop-editor.MuiButtonBase-root": {
      gap: "0.3rem",
      borderRadius: "4px",
      minWidth: "110px",
      padding: "7px 12px",
      justifyContent: "space-between",
      border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      width: "100%",
      height: "40px",
      flex: 1,
      "&:hover": {
        border: "1px solid var(--md-sys-color-on-surface, rgba(0, 0, 0, 1))",
      },
    },
    ".prop-editor-binding-layout.MuiStack-root": {
      flex: 1,
    },
    ".prop-editor-multiselect.MuiAutocomplete-root": {
      marginTop: 0,
      marginLeft: "auto",
      flex: 1,
      ".MuiInputBase-root": {
        height: "fit-content",
        minHeight: "40px",
        ".MuiInputBase-input": {
          padding: "8px 12px",
          fontSize: "12px",
        },
      },

      label: {
        color: "var(--md-sys-color-on-surface-dim, rgba(46, 46, 46, 0.60))",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
      ".MuiChip-root": {
        maxHeight: "26px",
      },
      ".MuiFormControl-root": {
        margin: 0,
        ".MuiInputBase-root": {
          padding: 0,
        },
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      },
    },

    ".prop-editor-switch.MuiFormControlLabel-root": {
      height: "32px",
      marginLeft: 0,
      width: "100%",
      justifyContent: "space-between",
      ".MuiTypography-root": {
        color: "var(--md-sys-color-on-surface, rgba(46, 46, 46, 0.87))",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        paddingTop: "2px",
      },
    },

    ".prop-editor-fields-layout": {
      flex: 1,
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        borderColor: "var(--md-sys-color-outline-variant, #86868B)",
      },
    },

    ".MuiFormControl-root.MuiTextField-root": {
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        borderColor: "var(--md-sys-color-outline-variant, #86868B)",
      },
      ".Mui-disabled": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--md-sys-color-outline-variant, #86868B)",
        },
      },
    },

    ".dimension-editor-input.MuiFormControl-root": {
      ".MuiInputBase-root": {
        height: "40px",
        padding: "8px 12px !important",
      },
      input: {
        padding: 0,
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        borderColor: "var(--md-sys-color-outline-variant, #86868B)",
      },
    },

    ".biding-input-button.MuiButtonBase-root": {
      height: "32px",
      width: "32px",
      minWidth: "32px !important",
      alignSelf: "center",
      marginTop: "9px",
      "MuiButton-startIcon": {
        margin: 0,
      },
    },

    ".header-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px",
    },

    ".search-bar": {
      margin: "10px 10px 14px 10px",
      input: {
        padding: "5px",
        borderRadius: "8px var(--md-sys-shape-none, 0px) var(--md-sys-shape-none, 0px) 8px",
        color: "var(--md-sys-color-on-surface-dim, rgba(46, 46, 46, 0.60))",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "109.023%",
      },
      ".MuiInputBase-root": {
        height: 32,
      },
      ".MuiFormControl-root": {
        marginTop: 0,
      },
      ".MuiOutlinedInput": {
        border: "1px solid var(--md-sys-color-outline-variant, #ccc)",
      },
    },

    ".library": {
      display: "flex",
      flexDirection: "column",
      gap: 0,
      padding: 0,
      margin: "0 auto",
      width: "100%",
      ".MuiAccordion-root.Mui-expanded": {
        margin: 0,
      },
      ".MuiPaper-root": {
        boxShadow: "none",
      },
    },

    ".card-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "8px",
      width: "78px",
      gap: "0.5rem",
      cursor: "pointer",
      ":hover": {
        color: "var(--md-sys-color-surface-focused, #0EA3E7)",
        backgroundColor: "var(--md-sys-color-surface-container, rgba(14, 163, 231, 0.05))",
        borderRadius: "2px",
      },
    },

    ".icon-container": {
      width: "70px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    ".title-container": {
      width: "70px",
      overflow: "none",
      whiteSpace: "wrap",
      textAlign: "center",
      fontSize: "12px",
      span: {
        textWrap: "balance",
      },
    },

    ".label-container": {
      width: "100%",
      gap: "1rem",
      display: "flex",
      alignItems: "center",
      textAlign: "start",
      fontSize: "12px",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      marginLeft: "8px",
      marginRight: "8px",
      cursor: "pointer",
      span: {
        paddingTop: "3px",
      },
      svg: {
        width: "20px",
        height: "20px",
      },
      ":hover": {
        color: "var(--md-sys-color-surface-focused, #0EA3E7)",
        backgroundColor: "rgba(14, 163, 231, 0.05)",
        borderRadius: "2px",
      },
    },

    ".library-cards-container.MuiAccordionDetails-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      // justifyContent: "space-between",
    },

    ".draggable-icon": {
      cursor: "grab",
    },
    ".item-properties-title.MuiTypography-root": {
      color: "var(--md-sys-color-on-surface, rgba(46, 46, 46, 0.87))",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      alignSelf: "center",
    },
    ".grid-stack-item-content": {
      padding: "0",
    },
    ".ComponentLibraryContainer.grid-stack-item .grid-stack-item-content": {
      padding: 0,
    },
    ".editor-view-mode.MuiAutocomplete-root": {
      margin: "0 1rem",
      "& > div": {
        margin: 0,
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    ".component-editor-page.MuiStack-root": {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    ".component-library.MuiStack-root, .properties-editor.MuiStack-root, .editor-frame.MuiStack-root": {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    ".component-library.MuiStack-root": {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      width: "100%",
      minWidth: "200px",
      borderRight: "1px solid var(--md-sys-color-outline-variant, #cecece)",
    },
    ".properties-editor.MuiStack-root": {
      width: "100%",
      minWidth: "440px",
      borderLeft: "1px solid var(--md-sys-color-outline-variant, #cecece)",

      "&>.MuiBox-root": {
        borderRight: "2px solid var(--md-sys-color-outline-variant, #E5E5E5)",
      },
    },
    ".editor-frame.MuiStack-root": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    ".editor-top-bar.MuiStack-root": {
      borderBottom: "1px solid var(--md-sys-color-outline-variant, #cecece)",
      minHeight: "56px",
      width: "100%",
      flexShrink: 0,
      flexWrap: "wrap",
      backgroundColor: "var(--md-sys-color-surface, white)",
    },
    ".editor-iframe.MuiStack-root": {
      flexGrow: 1,
      overflow: "auto",
      width: "100%",
      height: "100vh",
    },
    ".control-label": {
      "&.MuiTypography-root": {
        color: "var(--md-sys-color-on-surface, #323232)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        paddingRight: "12px",
      },
      "&.MuiFormLabel-root": {
        color: "var(--md-sys-color-on-surface, rgba(46, 46, 46, 0.87))",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
    },

    ".icon-selector-label": {
      fontFamily: "Inter",
      fontSize: "12px",
      paddingLeft: "10px",
    },
    ".icon-selector-delete": {
      color: "var(--md-sys-color-on-surface-neutral-variant, grey)",
      cursor: "pointer",
      marginLeft: "auto",
      marginRight: "10px",
    },
    ".icon-scaled-down.MuiSvgIcon-root": {
      transform: "scale(0.4)",
      color: "var(--md-sys-color-shadow, rgba(0, 0, 0, 0.10))",
    },
    ".position-box.MuiFormGroup-root": {
      border: "1px solid var(--md-sys-color-outline-variant, lightgrey)",
      width: "min-content",
      ".MuiBox-root": {
        width: "fit-content",
      },
    },
    ".display-type-radio-group": {
      width: "100%",
      ".display-type-selected": {
        backgroundColor: "var(--md-sys-color-surface-container, #EFEFEF)",
      },
      "label.MuiFormControlLabel-root": {
        margin: 0,
        height: 40,
        flex: 1,
        justifyContent: "center",
        color: "var(--md-sys-color-on-background, #000)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        "&:nth-of-type(1)": {
          borderRadius: "4px 0px 0px 4px",
          border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
        },
        "&:nth-of-type(2)": {
          borderRadius: "0px 4px 4px 0px",
          borderTop: "1px solid var(--md-sys-color-outline-variant, #86868B)",
          borderRight: "1px solid var(--md-sys-color-outline-variant, #86868B)",
          borderBottom: "1px solid var(--md-sys-color-outline-variant, #86868B)",
        },
      },
    },
    ".layout-editor-radio-button": {
      border: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      padding: 0,
      borderRadius: "4px",
      width: "fit-content",
      flexWrap: "nowrap !important",
      ".MuiButtonBase-root": {
        borderRadius: 0,
        "&.Mui-checked": {
          backgroundColor: "var(--md-sys-color-surface-container, #EFEFEF)",
        },
      },
      ".layout-editor-radio-child:not(:last-child)": {
        borderRight: "1px solid var(--md-sys-color-outline-variant, #86868B)",
      },
      ".layout-editor-radio-child:last-child": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      ".layout-editor-radio-child:first-of-type": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      ".layout-editor-radio-child": {
        padding: "8px 12px",
        width: "100%",
        minWidth: "50px",
      },
    },
    ".prop-editor-button.MuiButtonBase-root": {
      minWidth: "32px !important",
      ".MuiButton-startIcon": {
        margin: 0,
      },
    },
    ".icon-rotate-90": {
      "&.MuiSvgIcon-root": {
        transform: "rotate(90deg)",
      },
      ".MuiSvgIcon-root": {
        transform: "rotate(90deg)",
      },
    },
    ".icon-rotate-270 .MuiSvgIcon-root": {
      transform: "rotate(270deg)",
    },
    ".components-library-tab.MuiTabs-root": {
      ".MuiTab-root": {
        "&.MuiButtonBase-root": {
          flex: "1",
          "&.Mui-selected .MuiTypography-root": {
            color: "var(--md-sys-color-on-surface, #2E2E2E)",
          },
          ".MuiTypography-root": {
            color: "var(--md-sys-color-on-surface-dim, rgba(46, 46, 46, 0.60))",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          },
        },
      },
    },
    ".components-library-tabs": {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      flex: 1,
      zIndex: 0,
      ".components-library-content": {
        overflow: "auto",
      },
    },
    ".component-library": {
      borderRight: "1px solid var(--md-sys-color-outline-variant, #cecece)",
      ".layers-label": {
        border: "0.75px solid var(--md-sys-color-outline-variant, rgba(0, 0, 0, 0.12))",
        borderRight: "0",
        background: "var(--md-sys-color-surface-container, #F5F5F7)",
        padding: "12px",
        zIndex: 0,
      },
      ".tree-view": {
        flex: 1,
        overflow: "auto",
        color: "var(--md-sys-color-on-surface, #323232)",
      },
    },
    "::-webkit-scrollbar": {
      width: "18px",
    },

    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    ".editor-iframe::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--md-sys-color-surface-neutral, #a8bbbf)",
    },

    "::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--md-sys-color-surface-container, #f3f3f3)",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },

    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "var(--md-sys-color-surface-neutral, #a8bbbf)",
    },

    ".input-border-width": {
      ".MuiInputBase-root": {
        width: "100px",
        height: "40px",
      },
    },
    ".layout-editor-container": {
      overflowX: "auto",
      "> .MuiAccordionDetails-root": {
        padding: 0,
      },
      ".container-label.MuiTypography-root": {
        color: "var(--md-sys-color-on-surface, #323232)",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "109.023%",
        textTransform: "capitalize",
        position: "absolute",
        left: "0",
      },
      ".size-inputs": {
        padding: "5px",
        margin: "2px",
        "> div:first-of-type": {
          borderRight: "0.75px solid var(--md-sys-color-outline-variant, rgba(0, 0, 0, 0.12))",
          paddingRight: "5px",
        },
        "> div:nth-of-type(2)": {
          paddingLeft: "5px",
        },
        "> div": {
          width: "fit-content",
        },
        ".MuiTypography-root": {
          width: "30px",
          color: "var(--md-sys-color-on-surface-neutral, #828282)",
          fontFamily: "Inter",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "109.023%",
          textTransform: "capitalize",
          alignSelf: "center",
          marginRight: "3px",
        },
      },
      ".MuiTextField-root": {
        margin: 0,
        width: "35px",
        alignSelf: "center",
        fieldset: {
          border: "none",
        },
        "input.MuiInputBase-input": {
          height: "16px",
          padding: "6px 2px",
          fontSize: "10px",
          textAlign: "center",
          "&::placeholder": {
            fontSize: "22px",
            color: "var(--md-sys-color-on-surface-neutral, #8f8f8f)",
            transform: "translateY(5px)",
          },
        },
      },
    },
    ".layout-box-styles.MuiFormControl-root": {
      ".MuiInputBase-root": {
        "input::placeholder": {
          color: "var(--md-sys-color-on-background, #000000)",
        },
      },
    },
    ".button-color": {
      color: "var(--md-sys-color-on-surface, #323232)",
      width: "100%",
      maxWidth: "max-content",
    },
    ".MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    ".style-configuration-radio-group": {
      ".MuiButtonBase-root.MuiIconButton-root": {
        borderRadius: "2px",
      },
      ".MuiFormControlLabel-root": {
        flex: 1,
        height: "34px",
        "span.MuiTypography-root": {
          fontSize: "14px",
        },
      },
    },
    ".style-configuration-add-new-modal": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: "var(--md-sys-color-surface, white)",
      padding: "25px",
      alignItems: "center",
    },

    ".configuration-editor-container": {
      display: "flex",
      flexDirection: "column",
      minWidth: "fit-content",
      width: "100%",
      paddingBottom: "1rem",
      borderBottom: "1px solid var(--md-sys-color-outline-variant, #aab4be)",
    },
    ".layout-editor-position-container": {
      width: "100%",
      paddingBottom: "2rem",
      marginBottom: "1rem",
      borderBottom: "1px solid var(--md-sys-color-outline-variant, #aab4be)",
    },
    ".prop-editor-icon-color": {
      color: "var(--md-sys-color-on-surface-dim, rgba(0, 0, 0, 0.6))",
    },
    ".prop-editor-layouts-container": {
      flexDirection: "column",
      width: "100%",
    },
    ".style-editor": {
      ".toggle-binding-button.MuiButtonBase-root": {
        position: "absolute",
        top: "9px",
        right: "60px",
      },
    },
    ".custom-grid-menu": {
      ".MuiPaper-root > ul.MuiList-root": {
        paddingTop: 0,
      },
    },
    ".MuiPaper-root.MuiAccordion-root": {
      color: "var(--md-sys-color-on-surface, #323232)",
    },
  };

  const pageEditorGUIPage: graphql.Page = {
    __typename: "Page",
    Id: "0xa5",
    Name: "Page Editor",
    Classes: ["component-editor-page"],
    ModalPopups: [
      editStyles,
      editCellLayout,
      editFormats,
      editSampleData,
      editInputsPopup,
      editActionsPopup,
      createNewPropertyDialog,
      complexSampleDataEditorPopup,
    ],
    Services: services,
    Styles: componentEditorGuiStyles,
    State: {
      Message: "",
      Page: {
        __typename: "Page",
        Id: `${source}-editor`,
        Name: `${source}-editor`,
        InDesignMode: true,
        Items: [],
        Styles: {
          ".editor-page": {
            width: "100%",
            height: "100%",
            border: "solid 1rem #DCE2E5",
            background: "#f2f2f2",
            overflow: "hidden",
          },
          ".editor-component": {
            width: "100%",
            height: "100%",
            "& > div": {
              overflow: "hidden",
              width: "100%",
              height: "100%",
              border: "solid 1rem #DCE2E5",
              background: "#f2f2f2",
            },
          },
        },
        Layout: {
          __typename: "FlexLayout",
          Direction: "column",
          Classes: [`editor-${source}`],
        },
        Actions: [
          {
            Trigger: "onChange",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {},
                    },
                  ],
                  NextCommandIdOnSuccess: "1",
                },
                {
                  Id: "1",
                  Instruction: {
                    Name: "SendMessageToParent",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        Page: "Event",
                        Type: "'onPageUpdate'",
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onSelected",
            CommandSet: {
              FirstCommandId: "4",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "4",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.SelectedComponent": "Event",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "5",
                },
                {
                  Id: "5",
                  Instruction: {
                    Name: "SendMessageToParent",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        SelectedComponent: "Event",
                        Type: "'onSelectedComponent'",
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onCopy",
            CommandSet: {
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.CopiedComponent": "State.SelectedComponent",
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            Trigger: "onPaste",
            CommandSet: {
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.Page":
                          "generatePageWithClonedMeta(State.Page, State.SelectedComponent, State.CopiedComponent)",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "2",
                },
                {
                  Id: "2",
                  Instruction: {
                    Name: "SendMessageToParent",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        Page: "State.Page",
                        Type: "'onPageUpdate'",
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      ToggleExpanded: {},
    },
    InDesignMode: false,
    Items: [
      {
        __typename: "SimpleContainer",
        Id: "ComponentLibraryContainer",
        Classes: ["component-library"],
        UniqueName: "ComponentLibrary",
        Resizable: { startWidth: "18%" },
        Items: [
          {
            __typename: "TabContainer",
            Id: "componentsTabContainer",
            Classes: ["components-library-tabs"],
            TabClasses: ["components-library-tab"],
            ContentClasses: ["components-library-content"],
            Resizable: { startHeight: "50%", direction: "bottom" },
            Items: [
              {
                __typename: "TabItem",
                Id: "controlsTab",
                Title: "Controls",
                Items: [controlLibrary],
              },
              {
                __typename: "TabItem",

                Id: "componentsTab",
                Title: "Components",
                Items: [componentLibrary],
              },
            ],
          } as graphql.TabContainer,
          {
            __typename: "Label",
            DefaultValue: "Layers",
            Classes: ["layers-label"],
          },
          {
            __typename: "RichTreeList",
            Id: "tree-view",
            Searchable: true,
            Classes: ["tree-view"],
            Bindings: {
              Items: "buildTreeItems(State.Page.Items[0], ['Items', 'Groups'])",
              SelectedItem: "State.PropertiesEditorData.Id",
            },
            Style: {
              "& .MuiTreeItem-label": {
                fontSize: "0.8rem",
                textWrap: "pretty",
              },
            },
            Actions: [
              {
                Trigger: "onSelect",
                CommandSet: {
                  Id: "1",
                  FirstCommandId: "1",
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "selectItems",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            Selected: "Event",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                Trigger: "onDrop",
                CommandSet: {
                  Id: "1",
                  FirstCommandId: "1",
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "Transform",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "MoveMetadataItem",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            InputData: "{ DropData: Event, Page: State.Page }",
                          },
                        },
                        {
                          Name: "DataTransformations",
                          Value: {
                            "State.Page": "Event.TransformedData.Page",
                          },
                        },
                      ],
                      NextCommand: "CommandResult.Changed ? '2' : null",
                    },
                    setPageCommand,
                  ],
                },
              },
              {
                Trigger: "onCopy",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.CopiedComponent": "State.PropertiesEditorData",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                Trigger: "onPaste",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.Page":
                              "generatePageWithClonedMeta(State.Page, State.PropertiesEditorData, State.CopiedComponent)",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    setPageCommand,
                  ],
                },
              },
            ],
          },
        ],
        Layout: {
          __typename: "FlexLayout",
          Direction: "column",
        },
      },
      {
        __typename: "SimpleContainer",
        Id: "iframeWorkspace",
        UniqueName: "ComponentIFramePanel",
        Classes: ["editor-frame"],
        Items: [
          {
            __typename: "SimpleContainer",
            Id: "resolutionButtonsContainer",
            Items: [...IFrameToolbarItems, IFrameEditorViewMode, IFrameSavingIndicator, ...IFrameDesignerViewSelectors],
            Layout: {
              __typename: "FlexLayout",
              Id: "FlexLayout1121",
              Direction: "row",
              JustifyContent: "flex-end",
              AlignItems: "center",
              Spacing: 5,
              Classes: ["editor-top-bar"],
              Style: {
                padding: "4px 0",
              },
            },
          },
          componentIFrameContainer,
        ],
        Layout: {
          __typename: "FlexLayout",
          Direction: "column",
        },
      },
      propertiesEditor,
    ],
    Layout: {
      __typename: "FlexLayout",
      Direction: "row",
      Spacing: "10",
    },
    Actions: [
      {
        Trigger: "onLoad",
        CommandSet: {
          Id: "1",
          FirstCommandId: "1",
          Commands: [
            {
              Id: "1",
              Instruction: {
                Name: "CallService",
              },
              Parameters: [{ Name: "ServiceName", Value: `Get${modSource}` }],
            },
          ],
        },
      },
    ],
  };
  return pageEditorGUIPage;
};

export default setEditor;
