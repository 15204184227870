import { GridSize, GridDirection, SxProps } from "@mui/material";

//FormControlLabel
import {
  Container,
  IApplication,
  LayoutItem,
  Maybe,
  Page,
  SimpleContainer,
  StyleProperties,
  Menu,
  ResponsivePropertyObject,
  FlexProperties,
  Dialog,
  TableItemProps,
  IField,
  Feature,
  Service,
  CustomLabel,
} from "../../resolvers-types";
import {
  isArray,
  isNil,
  isNumber,
  isObject,
  isString,
  toNumber,
  cloneDeepWith,
  isFunction,
  cloneDeep,
  debounce,
  has,
  isUndefined,
  transform,
  isNull,
  isEmpty,
} from "lodash-es";
import { ngEditorMenu } from "../sampleData/ngEditorMenu";
import { categoryIcons, fileExtensionIcons } from "../sampleData/categoryIcons";
import { getExprValue } from "./interpreter";
import { JwtPayload } from "jwt-decode";
import { LogTag, log } from "./logger";
import { v4 as uuidv4 } from "uuid";
import { Signal } from "@preact/signals-react";
import { useTheme } from "@mui/material/styles";
import { RuntimeContext } from "./NGFieldExtensions";
import { designerState } from "./designer";
import { ObjectCache } from "./ObjectCache";
import { GetAllCustomLabelsFromSite, GetSite } from "./dataService";
import { decodeJwt } from "oidc-spa/tools/decodeJwt";
import { c } from "vitest/dist/reporters-5f784f42.js";

const tag: LogTag = "utils";

declare global {
  interface Date {
    toUTCWithoutTimezone(): string;
  }
}

Date.prototype.toUTCWithoutTimezone = function (): string {
  const year = this.getUTCFullYear();
  const month = String(this.getUTCMonth() + 1).padStart(2, "0");
  const day = String(this.getUTCDate()).padStart(2, "0");
  const hours = String(this.getUTCHours()).padStart(2, "0");
  const minutes = String(this.getUTCMinutes()).padStart(2, "0");
  const seconds = String(this.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};

export type AppDictionary = {
  [key: string]: IApplication | LayoutItem;
};

export type LayoutItemDictionary = {
  [key: string]: LayoutItem;
};

/**
 * Returns the user's current system theme as 'light' or 'dark'.
 * Defaults to 'light' if the browser doesn't support matchMedia.
 *
 * @returns {string} 'light' or 'dark'
 */
export function getThemeModeFromUserBrowserPreference() {
  if (typeof window !== 'undefined' && window.matchMedia) {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    return darkModeQuery.matches ? 'dark' : 'light';
  }
  // Fallback to 'light' if matchMedia is not supported
  return 'light';
}

export function toBoolean(value: Maybe<string> | Maybe<boolean> | undefined): boolean {
  if (isNil(value)) return false;

  if (isString(value)) return value.toLowerCase() === "true";

  return value;
}

export function isNullOrEmpty(str: string | undefined | Maybe<string>): boolean {
  return isNil(str) || str === "";
}

export function isNullOrUndefined(str: string | undefined | Maybe<string> | number | Maybe<number>): boolean {
  return isNil(str) || isUndefined(str);
}

export function toNumberOrZero(v: any): number {
  if (isNumber(v)) return v;

  const n = toNumber(v);

  if (isNaN(n)) return 0;

  return n;
}
export const camelCaseToTitleCase = (str: string): string => {
  // Replace camelCase boundaries with spaces and capitalize each word
  return str
    .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
    .replace(/^./, (char) => char.toUpperCase()) // Capitalize the first letter
    .replace(/\s./g, (char) => char.toUpperCase()); // Capitalize each letter after a space
};

export function generateUID() {
  // // I generate the UID from two parts here
  // // to ensure the random number provide enough bits.
  // const firstPart = (Math.random() * 46656) | 0;
  // const secondPart = (Math.random() * 46656) | 0;
  // return ("000" + firstPart.toString(36)).slice(-3) + ("000" + secondPart.toString(36)).slice(-3);
  return generateRandomString(10);
}

function generateRandomString(length) {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < length; i++) {
    result += chars[array[i] % chars.length];
  }

  return result;
}

export function generateGuid() {
  return uuidv4();
}

export function getTempFileName(mimeType: string) {
  const parts = mimeType.split("/");
  return `${generateGuid()}.${parts[1]}`;
}

// export function generateUID() {
//   // I generate the UID from two parts here
//   // to ensure the random number provide enough bits.
//   const firstPart = (Math.random() * 46656) | 0;
//   const secondPart = (Math.random() * 46656) | 0;
//   return (
//     ("000" + firstPart.toString(36)).slice(-3) +
//     ("000" + secondPart.toString(36)).slice(-3)
//   );
// }

export function gridSize(sz: Maybe<string> | undefined): GridSize {
  if (isNil(sz)) return "auto";

  return toNumber(sz);
}

export function gridDirection(dir: Maybe<string> | undefined): GridDirection | null {
  if (isNil(dir)) return null;

  return dir as GridDirection;
}

export function isType<T>(t: unknown): t is T {
  return true;
}

export function isFormComponent(typeName: string) {
  const types = [
    "AIDialog",
    "BasicInput",
    "Button",
    "Checkbox",
    "InputField",
    "Label",
    "MultiSelect",
    "RadioGroup",
    "Slider",
    "Switch",
    "VisibleMenu",
    "ComponentLibrary",
    "Component",
  ];

  return types.includes(typeName);
}

// export function getAllApplicationsInPage(page: Page): AppDictionary {
//   const apps: AppDictionary = {};

//   page.Items?.forEach((container) => {
//     if (!isNil(container)) {
//       if (!isNil(container.Id)) apps[container.Id] = container;
//       getAllApplicationsInContainer(container, apps);
//     }
//   });

//   return apps;
// }

// function getAllApplicationsInContainer(container: Container, apps: AppDictionary) {
//   switch (container?.__typename) {
//     case "SimpleContainer": {
//       const simpleContainer = container as SimpleContainer;
//       getAllApplicationsInSimpleContainer(simpleContainer, apps);
//       break;
//     }
//   }
// }

// function getAllApplicationsInSimpleContainer(simpleContainer: SimpleContainer, apps: AppDictionary) {
//   simpleContainer?.Items?.forEach((app) => {
//     if (!isNil(app)) apps[app.Id] = app;
//   });

//   simpleContainer?.Items?.forEach((container) => {
//     if (!isNil(container)) getAllApplicationsInContainer(container, apps);
//   });
// }

export function getStateObjectNameForContextMenu(contextMenuId: string): string {
  if (contextMenuId.startsWith("NGContextMenu_")) return contextMenuId; // This is for the singleton for standard alert-like dialogs

  return `NGContextMenu_${contextMenuId}`;
}

export function bufferToBase64(buffer) {
  const binary = String.fromCharCode.apply(null, buffer);
  return window.btoa(binary);
}

export function getCurrentNumberedSession(): string {
  return window.location.pathname.split("/").filter(Boolean)[1];
}

export function getNumberedSessionPrefix(): string {
  return `/${window.location.pathname.split("/").filter(Boolean).slice(0, 2).join("/")}`;
}

export function areWeInImpersonationMode(): boolean {
  return areWeInNumberedSession();
}

export function areWeInNumberedSession(): boolean {
  return window.location.pathname.startsWith("/u/");
}

export function isSessionUrl(url: string): boolean {
  return url.startsWith("/u/");
}

export function isAbsoluteUrl(url: string): boolean {
  return url.toLowerCase().startsWith("http");
}

export function isExternalUrl(url: string): boolean {
  try {
    const currentUrl = window.location.origin;
    const targetUrl = new URL(url, currentUrl);
    return targetUrl.origin !== currentUrl;
  } catch (e) {
    log.error("utils", "Invalid URL:", url);
    return false;
  }
}

export function normalizeSessionUrl(url: string): string {
  if (isNullOrEmpty(url)) return url;

  if (isExternalUrl(url)) return url;

  if (areWeInNumberedSession()) {
    if (isSessionUrl(url)) return url;
    else return `${getNumberedSessionPrefix()}${fixedUrl(url)}`;
  }

  return fixedUrl(url);
}

export function fixedUrl(url: string): string {
  return url.indexOf("/") === 0 ? url : `/${url}`;
}

export function getURLForFeature(feature: Feature): string {
  if (!isNil(feature.PageUrl)) return normalizeSessionUrl(feature.PageUrl) as string;

  return "";

  //return feature.Page?.Name as string;
}

export function getIconNameFromCategory(category: string): string {
  return categoryIcons[category];
}

export function getIconNameFromExtension(extension: string): string {
  return fileExtensionIcons[extension ?? "File"];
}

export async function GetMenu() {
  return ngEditorMenu as Menu; // TODO: Get from server
}

export function removeFunctionsFromObject(obj: any): any {
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      if (typeof item === "function") {
        delete obj[index]; // Remove the function from the array
      } else if (typeof item === "object" && item !== null) {
        removeFunctionsFromObject(item); // Recursively process the array item
      }
    });
  } else {
    for (const prop in obj) {
      if (typeof obj[prop] === "function") {
        delete obj[prop]; // Remove the property if it's a function
      } else if (typeof obj[prop] === "object" && obj[prop] !== null) {
        removeFunctionsFromObject(obj[prop]); // Recursively process the nested object
      }
    }
  }
}

export function cloneDeepWithoutSelectedFields(object, fieldsToExclude) {
  const c = cloneDeep(object);
  fieldsToExclude.forEach((x) => {
    // eslint-disable-next-line no-prototype-builtins
    if (c.hasOwnProperty(x)) delete c[x];
  });

  return c;
}

// for (const key in input) {
//   // eslint-disable-next-line no-prototype-builtins
//   if (input.hasOwnProperty(key)) {
//     output[input[key]] = key;
//   }
// }

// export function cloneDeepWithoutSelectedFields(object, fieldsToExclude) {
//   return cloneDeepWith(object, (value, name, x, y) => {
//     console.log("val", value, typeof value, name, x, y);
//     if (!isNil(name) && isString(name)) {
//       if (fieldsToExclude.indexOf(name) > -1) return undefined;
//     }
//     // No need to handle arrays or objects specifically,
//     // `cloneDeepWith` will automatically recurse into them
//   });
// }

export function cloneDeepWithoutFunctions(object) {
  return cloneDeepWith(object, (value) => {
    if (isFunction(value)) {
      // Return undefined to exclude functions from the result
      return null;
    }
    // No need to handle arrays or objects specifically,
    // `cloneDeepWith` will automatically recurse into them
  });
}

export function base64ArrayBuffer(arrayBuffer) {
  let base64 = "";
  const encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a, b, c, d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}

type AnyObject = { [key: string]: any };
type RenameMap = { [oldName: string]: string };

export function swapKeysAndValues(input: RenameMap): RenameMap {
  const output: RenameMap = {};

  for (const key in input) {
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(key)) {
      output[input[key]] = key;
    }
  }

  return output;
}

export function createNestedObject(baseObj: object, keys: string[], value: any): object {
  //const keys = path.split('.');
  if (isNil(baseObj)) baseObj = {};

  let currentRef = baseObj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      currentRef[key] = value;
    } else {
      if (!currentRef[key]) {
        currentRef[key] = {};
      }
      currentRef = currentRef[key];
    }
  }

  return baseObj;
}

export function getSupportedPlaybackAudioMimeType() {
  const audio = document.createElement("audio");

  if (audio.canPlayType("audio/mpeg")) {
    log.info(tag, "Setting audio playback mimeType to audio/mpeg");
    return "audio/mpeg";
  } else if (audio.canPlayType("audio/webm")) {
    log.info(tag, "Setting audio playback mimeType to audio/webm");
    return "audio/webm";
  } else {
    log.error(tag, "No supported audio playback mimeType found");
    return null;
  }
}

export function getSupportedRecordingAudioMimeType() {
  if (MediaRecorder.isTypeSupported("audio/mpeg")) {
    log.info(tag, "Setting audio recording mimeType to audio/mpeg");
    return "audio/mpeg";
  } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
    log.info(tag, "Setting audio recording mimeType to audio/mp4");
    return "audio/mp4";
  } else if (MediaRecorder.isTypeSupported("audio/webm")) {
    log.info(tag, "Setting audio recording mimeType to audio/webm");
    return "audio/webm";
  } else {
    log.error(tag, "No supported audio recording mimeType found");
    return null;
  }
}

export function browserIsSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

function segmentToCamelCase(segment: string): string {
  return segment
    .split("-")
    .map((word, index) => {
      if (index === 0) return word;
      return word?.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}

export function slugToCamelCase(path: string): string {
  return path
    .split("/")
    .map((segment) => {
      if (segment.includes("-")) {
        return segmentToCamelCase(segment);
      }
      return segment;
    })
    .join("/");
}

export function makeSlug(name: string): string {
  if (name === null || name === undefined) {
    throw new Error("Name cannot be null or undefined");
  }

  // Convert to lowercase and remove diacritics (accents).
  const normalized: string = name.normalize("NFKD");
  const withoutDiacritics: string = normalized.replace(/[\p{Mn}]/gu, "");

  // Use regex to add hyphens between TitleCase words.
  const slug: string = withoutDiacritics.replace(/(\p{Lu}\p{Ll}+)/gu, "-$1");

  // Replace spaces and special characters with hyphens.
  const cleanedSlug: string = slug.replace(/[^a-zA-Z0-9-]/g, "");

  // Remove consecutive hyphens and trim leading/trailing hyphens.
  const finalSlug: string = cleanedSlug.replace(/-+/g, "-").replace(/(^-|-$)/g, "");

  return finalSlug.toLowerCase();
}

// type URLBindingObject = {
//   Name: string,
//   Value: {
//     [key: string]: string
//   }
// };

// export type GoToPageParam = {
//   Name: string,
//   Value: string | URLBindingObject
// };

// export function generateURL(data: GoToPageParam[]): string {
//     let pageName = '';
//     const queryParams: string[] = [];

//     for (const item of data) {
//         if (item.Name === "Page.UniqueName") {
//             pageName = item.Value as string;
//         } else if (item.Name === "URLBindings") {
//             const bindings = item.Value as URLBindingObject;
//             for (const [key, value] of Object.entries(bindings)) {
//                 queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
//             }
//         }
//     }

//     return `${pageName}?${queryParams.join('&')}`;
// }

// export function renamePropertiesInArray(inputArray: AnyObject[], renameMap: RenameMap): AnyObject[] {
//   return inputArray.map(obj => renameProperties(obj, renameMap));
// }
// export function renameProperties(input: AnyObject, renameMap: RenameMap): AnyObject {
//     if (!input || typeof input !== 'object') return input;

//     const output: AnyObject = {};

//     for (const key in input) {
//         // eslint-disable-next-line no-prototype-builtins
//         if (input.hasOwnProperty(key)) {
//             const value = input[key];
//             // Check if the current key needs to be renamed
//             if (renameMap[key]) {
//                 output[renameMap[key]] = (typeof value === 'object') ? (Array.isArray(value)?renamePropertiesInArray(value, renameMap):renameProperties(value, renameMap)) : value;
//             } else {
//                 output[key] = (typeof value === 'object') ? (Array.isArray(value)?renamePropertiesInArray(value, renameMap):renameProperties(value, renameMap)) : value;
//             }
//         }
//     }

//     return output;
// }
export function findDialogs(obj) {
  const dialogs = [] as Dialog[];

  function traverse(current, depth) {
    if (depth > 10) return;

    depth++;

    if (Array.isArray(current)) {
      // If current is an array, traverse each element
      current.forEach((element) => traverse(element, depth));
    } else if (current !== null && typeof current === "object") {
      // If current is an object, check for __typename
      if (current.__typename === "Dialog") {
        dialogs.push(current);
      }
      // Recursively explore its properties
      Object.values(current).forEach((value) => traverse(value, depth));
    }
  }

  traverse(obj, 0);
  return dialogs;
}

export function traverse(current, depth, collection, type) {
  if (depth > 10) return;

  depth++;

  if (Array.isArray(current)) {
    // If current is an array, traverse each element
    current.forEach((element) => traverse(element, depth, collection, type));
  } else if (current !== null && typeof current === "object") {
    // If current is an object, check for __typename
    if (current.__typename === type) {
      collection.push(current);
    }
    // Recursively explore its properties
    Object.values(current).forEach((value) => traverse(value, depth, collection, type));
  }
}

export function findReferences(obj) {
  const refs: any[] = [];

  traverse(obj, 0, refs, "Reference");
  return refs;
}

const THEMES = ["light", "dark"];

export const removeAllBodyThemes = () => {
  THEMES.forEach((theme) => {
    document.body.classList.remove(`${theme}-theme`);
  });
};

/** Used as references for various `Number` constants. */
const MAX_SAFE_INTEGER = 9007199254740991;

/** Used to detect unsigned integer values. */
const reIsUint = /^(?:0|[1-9]\d*)$/;

/**
 * Checks if `value` is a valid array-like index.
 *
 * @private
 * @param {*} value The value to check.
 * @param {number} [length=MAX_SAFE_INTEGER] The upper bounds of a valid index.
 * @returns {boolean} Returns `true` if `value` is a valid index, else `false`.
 */
export function isIndex(value, length = MAX_SAFE_INTEGER): boolean {
  const type = typeof value;

  return (
    !!length &&
    (type === "number" || (type !== "symbol" && reIsUint.test(value))) &&
    value > -1 &&
    value % 1 === 0 &&
    value < length
  );
}

export function getStyleObject(
  style: Maybe<StyleProperties> | Maybe<FlexProperties> | undefined,
  defaults: any = undefined
) {
  if (isNil(style)) return undefined;

  const o = defaults ?? {};

  for (const str in style) {
    const key = str?.charAt(0).toLowerCase() + str.slice(1);

    if (Object.prototype.hasOwnProperty.call(style, key) || Object.prototype.hasOwnProperty.call(style, str)) {
      o[key] = style[str];
    }
  }

  return o;
}

export function getsxObject(
  style: Maybe<StyleProperties> | Maybe<FlexProperties> | undefined,
  defaults: SxProps = {}
): SxProps {
  const o = defaults ?? {};

  if (isNil(style)) {
    return o;
  }

  for (const str in style) {
    const key = str; //str.charAt(0).toLowerCase() + str.slice(1);

    if (Object.prototype.hasOwnProperty.call(style, key) || Object.prototype.hasOwnProperty.call(style, str)) {
      if (key[0] === "&" || key[0] === ":") {
        o[key] = getsxObject(style[str], {});
      } else {
        o[key] = getResponsiveObject(style[str]);
      }
    }
  }

  return o;
}

function getThemeProperty(prop) {
  if (isNil(prop) || !isString(prop)) return prop;
  if (!prop.startsWith("`")) return prop;

  const scope = {
    State: {}, // local,
    theme: {}, // TODO: inject the actual theme
  };

  return getExprValue(prop.replace(/`/g, ""), scope, null);
}

export function getResponsiveObject(responsiveProperty: ResponsivePropertyObject) {
  if (isNil(responsiveProperty)) return undefined;

  if (!isObject(responsiveProperty))
    //typeof responsiveProperty === "string" )
    return getThemeProperty(responsiveProperty);

  return {
    xs: getThemeProperty(responsiveProperty["xs"]),
    sm: getThemeProperty(responsiveProperty["sm"]),
    md: getThemeProperty(responsiveProperty["md"]),
    lg: getThemeProperty(responsiveProperty["lg"]),
    xl: getThemeProperty(responsiveProperty["xl"]),
  };
}

export function getVisibleProp(obj: object): boolean | undefined {
  if ("Visible" in obj) return (obj as any)["Visible"];

  return true;
}

export function parseCookies() {
  return document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});
}

export function parseAuthCookies() {
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});

  if (!areWeInNumberedSession()) {
    return cookies;
  }

  const tabId = getCurrentNumberedSession();

  if (tabId) {
    return {
      ...cookies,
      "Api.Token": cookies[`Api.Imp.${tabId}.Token`],
      "Api.NatsJwt": localStorage.getItem(`Api.Imp.${tabId}.NatsJwt`),
      "Api.NatsSeed": localStorage.getItem(`Api.Imp.${tabId}.NatsSeed`),
    };
  }

  return cookies;
}

let _repositoryAssetsBaseUrl: string | null = null;

export function getBackendUrl() {
  return "";
  //import.meta.env.VITE_BACKEND_HOST;
}

export function getApiUrl() {
  return "/api"; // getBackendUrl() +
}

export function getRepositoryAssetsBaseUrl() {
  if (_repositoryAssetsBaseUrl) return _repositoryAssetsBaseUrl;

  const cookies = parseAuthCookies();
  const token = getTenantToken(cookies);
  if (!token) {
    return _repositoryAssetsBaseUrl;
  }

  let project = token.project;
  if (isNullOrEmpty(project)) {
    log.error(tag, "Project config is missing");
  }

  const branch = cookies["Api.Branch"];
  if (branch) {
    project += `branches/${branch}`;
  }

  const tenant = getTenant(cookies);
  _repositoryAssetsBaseUrl = `/Repository/DEV/${tenant}/${project}/Assets/`;

  return _repositoryAssetsBaseUrl;
}

let _environmentBaseUrl: string | null = null;

export function getEnvironmentBaseUrl() {
  if (_environmentBaseUrl) return _environmentBaseUrl;

  let v = import.meta.env.VITE_BACKEND_HOST;

  if (isNullOrEmpty(v))
    throw new Error(
      "VITE_BACKEND_HOST is not defined.  You must define it in the .env file, eg. VITE_BACKEND_HOST=https//localhost:5001"
    );

  if (!v.endsWith("/")) v += "/";
  _environmentBaseUrl = v;

  return _environmentBaseUrl;
}

let _filesNatsBaseUrl: string | null = null;

export function getFilesNatsBaseUrl() {
  if (_filesNatsBaseUrl) return _filesNatsBaseUrl;

  _filesNatsBaseUrl = getApiUrl() + "/files/nats/";

  return _filesNatsBaseUrl;
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function getUser(token: ApiToken) {
  return {
    Name: token.name,
    FirstName: token.given_name,
    LastName: token.family_name,
    UserName: token.email,
    TenantId: token.tenant_id,
    Id: token.userId,
    Image: token.image,
    Tenant: token.tenant,
  };
}

export interface ApiToken extends JwtPayload {
  given_name: string;
  email: string;
  nameid: string;
  tenant_id: string;
  image: string;
}

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function setLocalStorage(name: string, value: any, days?: any) {
  // let expires = "";
  // if (days) {
  //   const date = new Date();
  //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //   expires = "; expires=" + date.toUTCString();
  // }
  window.localStorage.setItem(name, JSON.stringify(value));
}

export function deleteCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

export function minItemProps(item: LayoutItem, defaultTypename: string | undefined = undefined) {
  return {
    __typename: isNullOrEmpty(item.__typename) ? defaultTypename : item.__typename,
    Id: (item as any).Id,
    Name: (item as any).Name,
    SerialNumber: (item as any).SerialNumber ?? undefined,
  };
}

let registration: ServiceWorkerRegistration | null = null;
const authorizedUrl = import.meta.env.VITE_BACKEND_HOST + "/files";

export function updateWorkerToken(type, token) {
  if (isNil(registration)) return;

  const data = {
    type,
    token,
    authorizedUrl,
  };
  registration.active?.postMessage(data);
}

export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
      try {
        const getCurrentToken = () => {
          const cookies = parseAuthCookies();
          return cookies["Api.Token"];
        };

        registration = await navigator.serviceWorker.register(
          import.meta.env.MODE === "production" ? "/service-worker.js" : "/dev-sw.js?dev-sw"
        );

        navigator.serviceWorker.ready.then((registration) => {
          console.log("SW: ready", registration);
          updateWorkerToken("token.init", getCurrentToken());
        });

        console.log("SW: registered", registration, window);
      } catch (error) {
        log.error("ServiceWorker", "Registration failed:", error);
      }
    });
  }
}

export function getTableItemsProps(itemId: string, itemProps: TableItemProps[] | undefined, defaults: any) {
  if (isNil(itemProps) || itemProps.length == 0) return defaults;

  const props = itemProps.find((x) => x?.ItemId == itemId);

  const c = {
    sx: getsxObject(props?.Style, defaults?.sx),
  };

  return { ...defaults, ...c };
}

export function getInitials(name: string): string {
  if (isNullOrEmpty(name)) return "";

  // Split the name into parts, removing spaces from the resulting array
  const parts = name.split(" ").filter((x) => x.length > 0);

  // Check if the name has at least two parts
  if (parts.length >= 2) {
    // Take the first character of the first two parts and uppercase them
    return parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
  } else if (parts.length === 1) {
    // If there's only one part, take the first character of that part
    return parts[0][0].toUpperCase();
  }

  return "";
}

export function hasTimeProperties(options: Intl.DateTimeFormatOptions) {
  return options.timeStyle || options.hour || options.minute || options.second;
}

export function formatDate(v, options) {
  if (isNullOrEmpty(v)) return v;

  //const options = config.DateFormat;

  if (isNil(options)) return v;

  const dateObject = new Date(v);

  if (isNaN(dateObject.getTime())) {
    // log.error(tag, `Invalid date ${v}`);
    return v;
  }

  if (v.length === 10 && isNil(options.timeZone)) options.timeZone = "UTC"; //Ignore local timezone  when we're dateonly

  const formatter = new Intl.DateTimeFormat("en-US", options);
  // const formatter = new Intl.DateTimeFormat(options.Locale ?? "en-US", {
  //   dateStyle: (options.DateStyle as any) ?? undefined,
  //   timeStyle: (options.TimeStyle as any) ?? undefined,
  //   timeZone: options.TimeZone ?? "UTC",
  // });

  let s = formatter.format(dateObject);

  if (!isNullOrEmpty(options.Prefix)) s = options.Prefix + s;
  if (!isNullOrEmpty(options.Suffix)) s = s + options.Suffix;
  return s;
}

export function formatNumber(v, options) {
  //const options = config.NumberFormat;

  if (isNil(options)) return v;
  if (isNil(v)) return "";

  if (isNaN(v)) return "-";

  const style = options.Style?.toLowerCase();

  const formatter = new Intl.NumberFormat(options.Locale ?? "en-US", {
    style: style,
    currency: options.Currency ?? (style == "currency" ? "USD" : undefined),
    minimumFractionDigits: options.MinimumFractionDigits ?? undefined,
    maximumFractionDigits: options.MaximumFractionDigits ?? undefined,
    signDisplay: options.SignDisplay ?? undefined,
  });

  let s = formatter.format(v);

  if (!isNullOrEmpty(options.Prefix)) s = options.Prefix + s;
  if (!isNullOrEmpty(options.Suffix)) s = s + options.Suffix;
  return s;
}

export function formatBoolean(v, options) {
  if (isNil(options)) return v;

  if (v) return options.TrueValue ?? "Yes";
  return options.FalseValue ?? "No";
}

function getFileNameWithoutExtension(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf(".");

  if (lastDotIndex === -1 || lastDotIndex === 0) return fileName;

  return fileName.substring(0, lastDotIndex);
}

export function formatFileName(fileName) {
  if (!fileName) return;

  const baseFileName = getFileNameWithoutExtension(fileName);

  // Convert to lowercase and remove diacritics (accents).
  const normalized = baseFileName.normalize("NFD");
  const withoutDiacritics = normalized.replace(/[\u0300-\u036f]/g, ""); // Unicode range for diacritics

  // Use regex to add hyphens between TitleCase words.
  let slug = withoutDiacritics.replace(/([A-Z][a-z]+)/g, "-$1");

  // Replace spaces and special characters with hyphens.
  slug = slug.replace(/[^a-zA-Z0-9-]/g, "");

  // Remove consecutive hyphens and trim leading/trailing hyphens.
  slug = slug.replace(/-+/g, "-").replace(/^-|-$/g, "");

  return slug.toLowerCase();
}

export function getTestId(config: any, suffix: string | null = null) {
  if (isNil(config)) return undefined;

  suffix = suffix ? `-${suffix}` : "";

  if (isNil(config.ContextId)) return config.Id + suffix;

  return `${config.ContextId}-${config.Id ?? ""}${suffix}`;
}

export const getClassName = (classes: Signal<string[] | string>, context: RuntimeContext | null = null) => {
  return getClassNameFromString(classes.value, context);
};

export const getClassNameFromString = (
  classes: string | string[] | Maybe<string[] | undefined>,
  context: RuntimeContext | null = null
) => {
  const c = (isArray(classes) ? classes.join(" ") : classes) ?? "";

  if (context?.InDesignMode) {
    if (c == "") return "ng-design-mode";
    return c + " ng-design-mode";
  }

  return c;
};

export const useConfig = (component: string, config: IField) => {
  const theme = useTheme();
  const componentThemeProps = theme.components?.[component]?.properties ?? {};
  return { ...componentThemeProps, ...config };
};

export function keyByRec(collection, children, prop, result) {
  if (Array.isArray(collection)) {
    collection.forEach((item) => {
      if (isObject(item)) {
        if (item[prop]) {
          result[item[prop]] = item; // Use children property as key
        }
        if (item[children]) {
          keyByRec(item[children], children, prop, result); // Recurse on prop
        }
      }
    });
  } else if (isObject(collection)) {
    if (collection[prop]) {
      result[collection[prop]] = collection; // Use children property as key
    }
    if (collection[children]) {
      keyByRec(collection[children], children, prop, result); // Recurse on prop
    }
  }

  return result;
}

export function getRandomColorHex() {
  // Generate random values for red, green, and blue components
  const r = Math.floor(Math.random() * 256); // Random value between 0 and 255
  const g = Math.floor(Math.random() * 256); // Random value between 0 and 255
  const b = Math.floor(Math.random() * 256); // Random value between 0 and 255

  // Convert the values to hexadecimal and concatenate them
  const colorHex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
    .toString(16)
    .padStart(2, "0")}`;

  return colorHex;
}

export function makeArray(thing) {
  if (Array.isArray(thing)) {
    return thing;
  }
  return [thing];
}

export const convertPXStrToInt = (pxStr) => {
  if (!pxStr) {
    return null;
  }
  return parseInt(pxStr, 10);
};

export const splitCamelCase = (str?: string): string | null => {
  if (!str) return null;
  return str.replace(/^([A-Z])|([A-Z])(?=[a-z])/g, " $1$2").trim();
};

export const endsWithSuffix = (text) => {
  const suffixes = ["Outlined", "Rounded", "Sharp", "TwoTone"];
  const regex = new RegExp(`(${suffixes.join("|")})$`, "i"); // Case-insensitive matching
  return regex.test(text);
};

// export function findParentAndIndex(
//   root: any,
//   itemsProp: string,
//   needle: object
// ): { parent: any | null; index: number | null } | null {
//   function search(node, parent) {
//     const items = node[itemsProp];
//     if (isArray(items)) {
//       for (let i = 0; i < items.length; i++) {
//         const item = items[i];

//         if (Object.entries(needle).every(([k, v]) => item[k] == v)) {
//           return { parent: node, index: i };
//         }
//         const result = search(item, node);
//         if (result) {
//           return result;
//         }
//       }
//     }
//     return null;
//   }

//   return search(root, null);
// }

export function isVisible(defaultValue, config, context: RuntimeContext): boolean {
  //if (context.InDesignMode && context.DesignProps?.AllVisible) return true;

  if (isNil(context)) return defaultValue;

  if (context.InDesignMode && designerState?.ShowInvisibleComponents.value) return true;

  return defaultValue;
}

export const debouncedHandler = debounce((fn, e, value) => {
  if (!isNil(fn)) fn(e, value);
}, 1000);

export const runNextFrame = (fn) => {
  let cleanup: (() => void) | null = null;
  const frameId = requestAnimationFrame(() => {
    cleanup = fn();
  });

  return () => {
    if (frameId) {
      cancelAnimationFrame(frameId);
      if (cleanup) {
        cleanup();
      }
    }
  };
};

type AsyncFunction<T extends any[], R> = (...args: T) => Promise<R>;

export function createOptionalDebouncedFunction<T extends any[], R>(
  fn: AsyncFunction<T, R>
): (shouldDebounce: boolean, delay: number, ...args: T) => Promise<R> {
  let resolveCallback: ((value: R) => void) | null = null;
  let currentDebouncedFn: ((...args: T) => void) | null = null;
  let lastFn: AsyncFunction<T, R> | null = null;
  let lastDelay: number | null = null;

  return (shouldDebounce: boolean, delay: number, ...args: T): Promise<R> => {
    if (shouldDebounce) {
      // Check if debounce function needs to be recreated
      if (!currentDebouncedFn || fn !== lastFn || delay !== lastDelay) {
        lastFn = fn;
        lastDelay = delay;
        currentDebouncedFn = debounce(
          async (...innerArgs: T) => {
            const result = await fn(...innerArgs);
            if (resolveCallback) {
              resolveCallback(result);
              resolveCallback = null; // Reset the resolve callback
            }
          },
          delay,
          { leading: true }
        );
      }
      return new Promise<R>((resolve) => {
        resolveCallback = resolve;
        currentDebouncedFn!(...args);
      });
    } else {
      return fn(...args);
    }
  };
}

export const stringToCustomNumber = (input: string): number => {
  let result = 0;
  for (let i = 0; i < input.length; i++) {
    result += input.charCodeAt(i) * (i + 1); // Multiply ASCII value by position index + 1
  }
  return result;
};

export const getDOMElementById = (id: string) => document.getElementById(id);

export const queryDOMElementBySelector = (id: string, selector: string) =>
  document.querySelector(`[${selector}='${id}']`);

export const scrollToView = async (
  elementId: string,
  options?: { topSubtract?: number; wait?: number; testId?: string; behavior?: ScrollBehavior }
) => {
  const { wait = 0, topSubtract = 0, testId = "", behavior = "auto" } = options || {};
  let element = getDOMElementById(elementId);
  let parent = queryDOMElementBySelector(testId, "data-testid");
  let h = parent?.scrollHeight ?? 0;
  let s = parent?.clientHeight ?? 0;
  let tries = 0;
  let isElementVisible = elementIsVisibleInParent(element, parent);
  if (isElementVisible) return null;

  if (!!parent && !!element && h > s) {
    return parent?.scrollTo({ behavior, top: element?.offsetTop - topSubtract });
  }

  if (parent) {
    while ((h === s || (!element && h > s) || !isElementVisible) && tries < 10) {
      parent = queryDOMElementBySelector(testId, "data-testid");
      element = getDOMElementById(elementId);
      h = parent?.scrollHeight ?? 0;
      s = parent?.clientHeight ?? 0;
      tries++;
      if (element) {
        parent?.scrollTo({ top: element?.offsetTop, behavior });
      }
      isElementVisible = elementIsVisibleInParent(element, parent);
      await sleep(wait);
    }
    return null;
  }
  return element?.scrollIntoView({ block: "center", behavior });
};

export const elementIsVisibleInParent = (el: HTMLElement | null, parent?: Element | null, isWindow?: boolean) => {
  if (!el || !parent) return false;
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { top: pTop, left: pLeft, bottom: pBottom, right: pRight } = parent.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return isWindow
    ? top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
    : top >= 0 && left >= 0 && top >= pTop - 10 && bottom <= pBottom + 10 && right > pLeft && right < pRight;
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export function ignoreCustomHandlers(handlers: { [key: string]: any }): { [key: string]: any } {
  const filteredHandlers = {};
  Object.keys(handlers).forEach((handler) => {
    if (!handler.toLowerCase().includes("custom")) {
      filteredHandlers[handler] = handlers[handler];
    }
  });

  return filteredHandlers;
}

export function getDistinctKeys(arrayOfObjects) {
  const keys = new Set();

  function extractKeys(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.add(key);
        if (typeof obj[key] === "object" && obj[key] !== null) {
          extractKeys(obj[key]);
        }
      }
    }
  }

  arrayOfObjects.forEach((obj) => extractKeys(obj));

  return Array.from(keys);
}

export function getTenantToken(cookies = {}) {
  if (isEmpty(cookies)) {
    cookies = parseAuthCookies();
  }
  const jwt = cookies["Api.Token"];
  if (jwt) {
    return decodeJwt(jwt);
  }
  return null;
}

function extractTenantFromMapping(data, url) {
  let configObj;

  // If data is a string, attempt to parse it.
  if (typeof data === "string") {
    try {
      configObj = JSON.parse(data);
    } catch (err) {
      console.error("Invalid JSON provided:", err);
      return null;
    }
  } else {
    // Assume it's already an object
    configObj = data;
  }

  // Iterate over each key in the config object
  for (const key in configObj) {
    if (Object.prototype.hasOwnProperty.call(configObj, key)) {
      const patterns = configObj[key];
      if (Array.isArray(patterns)) {
        for (const pattern of patterns) {
          // If pattern is an empty string, skip it
          if (!pattern) continue;

          try {
            const regex = new RegExp(pattern);
            if (regex.test(url)) {
              return key; // Return the key where we found a match
            }
          } catch (regexError) {
            // If pattern isn't a valid regex, handle gracefully or log
            console.warn(`Invalid regex pattern "${pattern}" for key "${key}":`, regexError);
          }
        }
      }
    }
  }

  // No matches found
  return null;
}

export const getTenant = (() => {
  let cachedTenant: string | null = null;

  return function (cookies = {}) {
    if (cachedTenant) {
      return cachedTenant;
    }

    // If logged in use tenant from jwt
    const token = getTenantToken(cookies);
    cachedTenant = token?.tenant;
    if (cachedTenant) {
      return cachedTenant;
    }

    // If not logged in check query param first
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tenant = urlParams.get("tenant");
    if (tenant) {
      cachedTenant = tenant;
      return cachedTenant;
    }

    const hostname = window.location.hostname;

    const tenantMapping = import.meta.env.VITE_TENANT_MAPPING;
    const matchingTenant = extractTenantFromMapping(tenantMapping, hostname);
    if (matchingTenant) {
      cachedTenant = matchingTenant;
      return cachedTenant;
    }

    // If not logged in check hostname
    const parts = hostname.split(".");
    if (parts.length > 2) {
      cachedTenant = parts.slice(-2, -1)[0]; // e.g., 'pages' for frontend-9an.pages.dev
    } else {
      cachedTenant = parts[0]; // e.g., 'example' for 'example.com'
    }

    if (cachedTenant === "localhost") {
      cachedTenant = import.meta.env.VITE_DEFAULT_TENANT;
    }

    if (hostname.endsWith("pages.dev")) {
      cachedTenant = import.meta.env.VITE_DEFAULT_TENANT;
    }

    return cachedTenant;
  };
})();

export function isSignal(obj: any): boolean {
  return has(obj, "v");
}

export function fixNodeRemoveBug() {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error("Cannot remove a child from a different parent", child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error("Cannot insert before a reference node from a different parent", referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
}

export function removeTypeName(obj, typeName) {
  // Recursively traverse the object
  const deepClean = (item) => {
    if (Array.isArray(item)) {
      // Filter the array by removing any elements with __typename === typeName
      return item
        .map(deepClean) // Apply deepClean recursively to array elements
        .filter((subItem) => !(subItem && subItem.__typename === typeName));
    } else if (isObject(item)) {
      // For objects, delete any matching properties and recursively clean each property
      return transform(item, (result: any, value: any, key) => {
        if (!(value && value.__typename === typeName)) {
          result[key] = deepClean(value);
        }
      });
    }
    return item;
  };

  return deepClean(obj);
}

export const getNextImpersonationTabId = (): number => {
  const regex = /^Api\.Imp\.(\d+)\.NatsJwt$/;

  const vals: number[] = [];
  // Iterate over all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key) {
      const match = key.match(regex); // Check if the key matches the pattern

      if (match) {
        const tabId = parseInt(match[1]); // Extract the tabId as an integer

        if (!isNaN(tabId)) {
          //maxTabId = Math.max(maxTabId, tabId); // Update maxTabId if a larger one is found

          vals.push(tabId);
        }
      }
    }
  }

  if (vals.length === 0) return 0;

  const sortedVals = vals.sort((a, b) => a - b);

  for (let i = 0; i < sortedVals.length; i++) {
    if (sortedVals[i] !== i) {
      return i;
    }
  }

  return sortedVals.length;
};

export function clearLocalStorageForImpersonationSessions() {
  const storageKeys = Object.keys(localStorage);
  storageKeys.forEach((key) => {
    if (key.startsWith("Api.Imp.")) {
      localStorage.removeItem(key);
    }
  });
}

export function clearCookiesForImpersonationSessions() {
  // Clear all impersonation cookies when the admin logs out
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});

  for (const item in cookies) {
    if (item.startsWith(`Api.Imp.`)) {
      deleteCookie(item);
    }
  }
}

const maxCacheSize = 1000;
const labelCache = new ObjectCache<string>(maxCacheSize);
const nonAffectedStrings = new Set<string>();

export function getCustomLabel(v) {
  if (isNullOrEmpty(v)) return v;
  if (nonAffectedStrings.has(v)) return v;
  if (labelCache.has(v)) return labelCache.get(v);

  const customLabels = GetAllCustomLabelsFromSite();

  for (const customLabel of customLabels) {
    switch (customLabel.Method ?? "Full") {
      case "Full":
        if (
          customLabel.Label === v ||
          (customLabel.CaseSensitive === false && customLabel.Label.toLowerCase() === v.toLowerCase())
        ) {
          labelCache.set(v, customLabel.ReplaceBy);
          return customLabel.ReplaceBy;
        }
        break;
      default:
        throw new Error(`Custom Label Replacement Method '${customLabel.Method}' is not implemented yet`);
    }
  }

  nonAffectedStrings.add(v);
  return v;
}

// Usage example: Call this function with the path to the favicon based on your backend data
// updateFavicon('/path/to/favicon.ico');
export function updateFavicon(iconPath) {
  // Remove the existing favicon if it exists
  const existingFavicon = document.querySelector("link[rel*='icon']");
  if (existingFavicon) {
    existingFavicon.parentNode.removeChild(existingFavicon);
  }

  // Create a new link element for the favicon
  const newFavicon = document.createElement("link");
  newFavicon.rel = "icon";
  newFavicon.href = iconPath;

  // Append the new favicon link to the document head
  document.head.appendChild(newFavicon);
}

export function setupFavicon(theme: any) {
  if (theme.Favicon) {
    if (theme.Favicon.startsWith("http")) {
      updateFavicon(theme.Favicon);
    } else {
      updateFavicon(getAssetUrl(theme.Favicon));
    }
  }
}

export function setupAppTitle() {
  const site = GetSite();

  if (site.Title) {
    document.title = site.Title;
  }
}

export function getAssetUrl(file) {
  let url = getRepositoryAssetsBaseUrl() + "Images";
  if (!file.startsWith("/")) {
    url += "/";
  }
  url += file;
  return url;
}
