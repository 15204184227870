import { Icon } from "../../../resolvers-types";
import { INGButtonProps } from "../../library/NGFieldExtensions";
import { Button as MUIButton } from "@mui/material";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { effect, useSignal } from "@preact/signals-react";
import NGIcon from "../NGIcon/NGIcon";
import { Stack } from "@mui/system";
import { getTestId, getsxObject, getClassName, getCustomLabel } from "../../library/utils";
import { isNil } from "lodash-es";

export default function NGButton({ config, context }: INGButtonProps) {
  const shapeValues = {
    None: "var(--md-sys-shape-none)",
    ["Extra Small"]: "var(--md-sys-shape-extra-small)",
    Small: "var(--md-sys-shape-small)",
    Medium: "var(--md-sys-shape-medium)",
    Large: "var(--md-sys-shape-large)",
    ["Extra Large"]: "var(--md-sys-shape-extra-large)",
    Full: "var(--md-sys-shape-full)",
  };

  const testId = getTestId(config);

  if (!isNil(config.StartIcon)) {
    config.StartIcon.ContextId = testId;
    config.StartIcon.Id = `${config.Id}_StartIcon`;
  }
  if (!isNil(config.EndIcon)) {
    config.EndIcon.ContextId = testId;
    config.EndIcon.Id = `${config.Id}_EndIcon`;
  }
  if (!isNil(config.TopIcon)) {
    config.TopIcon.ContextId = testId;
    config.TopIcon.Id = `${config.Id}_TopIcon`;
  }
  if (!isNil(config.BottomIcon)) {
    config.BottomIcon.ContextId = testId;
    config.BottomIcon.Id = `${config.Id}_BottomIcon`;
  }

  const local = setupLocalState(
    config,
    {
      Label: useSignal(config.Label ?? ""),
      Color: useSignal(config.Color),
      Disabled: useSignal(config.Disabled ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
      Title: useSignal(config.Title ?? null),
      Shape: useSignal(config.Shape),
    },
    context
  );

  // handle clear (x button) cases
  effect(() => {
    if (config.Shape !== local.Shape.value) {
      local.Shape.value = config.Shape;
    }
  });

  const handlers = setupHandlers(config, context);

  // AA-TODO: Find a better way to deal with button width in design mode
  const width = context.InDesignMode && local.Style.value.Width == undefined ? "100%" : local.Style.value.Width;

  return (
    <>
      {local.Visible.value && (
        <MUIButton
          sx={getsxObject(local.Style.value, {
            textTransform: "none",
            width,
            borderRadius: shapeValues[local.Shape.value] || "none",
          })}
          startIcon={config.StartIcon && <NGIcon config={config.StartIcon as Icon} context={context} />}
          data-testid={testId}
          data-type={config.__typename}
          endIcon={config.EndIcon && <NGIcon config={config.EndIcon as Icon} context={context} />}
          className={`${getClassName(local.Classes)}`}
          color={local.Color.value}
          disabled={local.Disabled.value}
          disableElevation={config.DisableElevation as boolean}
          disableFocusRipple={config.DisableFocusRipple as boolean}
          disableRipple={config.DisableRipple as boolean}
          fullWidth={config.FullWidth as boolean}
          href={config.Href as string}
          size={config.Size as any}
          centerRipple={config.CenterRipple as boolean}
          disableTouchRipple={config.DisableTouchRipple as boolean}
          focusRipple={config.FocusRipple as boolean}
          focusVisibleClassName={config.FocusVisibleClassName as string}
          title={local.Title.value}
          {...handlers}
        >
          <Stack direction="column" spacing={1} alignItems="center">
            {config.TopIcon && <NGIcon config={config.TopIcon as Icon} context={context} />}
            {getCustomLabel(local.Label.value)}
            {config.BottomIcon && <NGIcon config={config.BottomIcon as Icon} context={context} />}
          </Stack>
        </MUIButton>
      )}
    </>
  );
}
